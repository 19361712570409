import React, { useState, useEffect } from 'react';
import { gapi } from 'gapi-script';
import Dashboard from './Dashboard';
import './App.css';

function App() {
  const [year, setYear] = useState(2021);
  const [texts, setTexts] = useState(null);

  // FIXME: Use the t() function to translate strings
  const t = (str) => {
    return str;
  };

  useEffect(() => {
    gapi.load('client', function() {
      gapi.client
        .init({
          apiKey: 'AIzaSyApxSpL08Sb5gvVvhXGWUgBVUKHivPY7-U',
          discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
        })
        .then(() => {
          gapi.client.load('sheets', 'v4', () => {
            gapi.client.sheets.spreadsheets.values
              .batchGet({
                spreadsheetId: '1UGQED1PiesrOv259a9ETXKUvCPc8uhigCxWJCGxWswk',
                ranges: ['Textos!A2:B'],
              })
              .then(
                response => {
                  const newTexts = {};
                  response.result.valueRanges[0].values.forEach(row => {
                    newTexts[row[0]] = row[1];
                  });
                  setTexts(newTexts);
                }
              )
          })
        })
    })
  }, []);

  if (!texts) {
    return (
      <div id="loading">
        {t('Carregando...')}
      </div>
    );
  }

  return (
    <div className="App">
      <header>
        <h1><a href="http://cidadaodigital.org.br"><span>Cidadão Digital</span></a></h1>
        <nav>
          <ul>
            <li><a href="http://cidadaodigital.org.br/">Sobre</a></li>
            <li><a href="http://cidadaodigital.org.br/#indicadores">Indicadores</a></li>
            <li><a href="http://cidadaodigital.org.br/#jovens">Jovens</a></li>
            <li><a href="https://cidadaodigital.org.br/educathon">Educathon</a></li>
            <li><a href="http://cidadaodigital.org.br/#educadores">Educadores</a></li>
            <li><a href="http://cidadaodigital.org.br/#recursos">Recursos</a></li>
          </ul>
        </nav>
      </header>
      <p className="intro" style={{ display: 'none' }}>
        <b>{t('O que é')}:</b><br />
        <span>{texts.dashboard_intro}</span>
      </p>
      <div className="intro stages" style={{ display: 'none' }}>
        <div id="stage-1">
          <b>{t('Etapa 1 - Seleção')}</b><br />
          <span>{texts.dashboard_stage_1}</span>
        </div>
        <div id="stage-2">
          <b>{t('Etapa 2 - Curso de formação')}</b><br />
          <span>{texts.dashboard_stage_2}</span>
        </div>
        <div style={{ paddingRight: 0 }} id="stage-3">
          <b>{t('Etapa 3 - Valendo')}</b><br />
          <span>{texts.dashboard_stage_3}</span>
        </div>
      </div>
        <div id="years">
          <div className={year === 2020 ? 'selected' : ''} onClick={() => { setYear(2020); }}>2020</div>
          <div className={year === 2021 ? 'selected' : ''} onClick={() => { setYear(2021); }}>2021</div>
          <div className={year === 2022 ? 'selected' : ''} onClick={() => { window.alert('Em breve!'); }}>2022</div>
        </div>
        { year === 2020 ?
          <Dashboard
            year={2020}
            ranges={[
              'Inscrições!A2:E',
              'Formação!A2:E',
              'Embaixadores!A2:K',
              'Formação Indicadores!A3:C',
              'Formação Indicadores!E3:G',
              'Formação Indicadores!I3:J',
              'Formação Indicadores!L2:L',
              'Embaixadores Indicadores (Ações)!A3:C',
              'Embaixadores Indicadores (Ações)!E3:G',
              'Embaixadores Indicadores (Ações)!I3:K',
              'Embaixadores Indicadores (Ações)!M2:M',
              'Embaixadores Indicadores (Ações)!O2:P',
              'Embaixadores Indicadores (Ações)!R2:AA',
            ]}
            enabledTabs={['enrol', 'formation', 'ambassadors']}
            hideRightBar={false}
          /> : null }
        { year === 2021 ?
          <Dashboard
            year={2021}
            ranges={[
              'Inscrições (2021)!A2:E',
              'Formação (2021)!A2:E',
              'Embaixadores (2021)!A2:K',
              'Formação Indicadores (2021)!A3:C',
              'Formação Indicadores (2021)!E3:G',
              'Formação Indicadores (2021)!I3:J',
              'Formação Indicadores (2021)!L2:L',
              'Embaixadores Indicadores (Ações) (2021)!A3:C',
              'Embaixadores Indicadores (Ações) (2021)!E3:G',
              'Embaixadores Indicadores (Ações) (2021)!I3:K',
              'Embaixadores Indicadores (Ações) (2021)!M2:M',
              'Embaixadores Indicadores (Ações) (2021)!O2:P',
              'Embaixadores Indicadores (Ações) (2021)!R2:AA',
            ]}
            enabledTabs={['enrol', 'formation', 'ambassadors']}
            hideRightBar={false}
          /> : null }
    </div>
  );
}

export default App;
