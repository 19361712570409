import React, { useState } from 'react';
import { Pixelify } from 'react-pixelify';

const AnimatedPixelify = ({ state, year }) => {
  const src = require(`./photos/${year}/${state}.jpg`);
  const [pixel, setPixel] = useState(20);

  if (pixel > 0) {
    setTimeout(() => {
      setPixel(pixel - 2);
    }, 100);
  }

  return (
    <div style={{ position: 'relative', width: 300, height: 300, overflow: 'hidden', zIndex: 2, border: '2px solid white' }}>
      <div style={{ position: 'absolute' }}>
        <Pixelify
          src={src}
          width={304}
          height={304}
          centered={true}
          pixelSize={pixel}
        />
      </div>
    </div>
  );
}

export default AnimatedPixelify;
