const mapCoords = {
  'AC': [-9.1267977, -71.4317499],
  'AL': [-9.6596458, -36.3940997],
  'AP': [1.490415, -51.8479555],
  'AM': [-4.062839, -63.6810296],
  'BA': [-12.4309041, -41.6643528],
  'CE': [-5.3083327, -39.3933226],
  'DF': [-15.7757867, -48.0785153],
  'ES': [-20.2821881, -40.320809],
  'GO': [-16.6374172, -50.1677642],
  'MA': [-4.8978594, -45.2504088],
  'MS': [-20.2737863, -54.8581258],
  'MT': [-12.8732096, -56.7010823],
  'MG': [-18.629488, -44.4882714],
  'PB': [-7.1630716, -37.9032751],
  'PA': [-4.6578951, -52.5861356],
  'PR': [-24.6128071, -52.4452961],
  'PE': [-8.3857176, -37.7383534],
  'PI': [-7.3340919, -42.2733105],
  'RJ': [-22.8237338, -43.5030222],
  'RN': [-5.4053116, -37.3019101],
  'RS': [-29.563967, -52.8889915],
  'RO': [-11.2193817, -62.5153579],
  'RR': [2.2730426, -61.8379881],
  'SC': [-27.2667074, -50.0377098],
  'SP': [-23.6821592, -46.876169],
  'SE': [-10.6284343, -37.4117372],
  'TO': [-10.049221, -48.3596618],
};
module.exports = mapCoords;
