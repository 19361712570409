import React, { useEffect } from 'react';
import $ from 'jquery';

const Map = (props) => {
  const { data, mapbox, currentTab, updateState } = props;

  useEffect(() => {
    window.setTimeout(
      () => {
        renderMap();
      },
      1000,
    );
  });

  const renderMap = () => {
    window.mapboxgl.accessToken = 'pk.eyJ1Ijoic2FmZXJuZXQiLCJhIjoiY2o4emNtOG84MDBtNjJ3bW8wbnVwendpZiJ9.49MUgzwbmQqIEOlyTuGXNA';
    const map = new window.mapboxgl.Map({
      style: 'mapbox://styles/safernet/ckaml5fk113rs1kqcx2adobrt',
      container: 'map-container',
      center: [-55, -15],
      zoom: 3,
      scrollZoom: true,
      touchZoomRotate: false,
      touchPitch: false,
    });

    let loaded = false;
    const features = [];
  
    map.on('load', () => {
      loaded = true;
    });

    map.on('click', (e) => {
      const clusters = map.queryRenderedFeatures(e.point, {
        layers: ['points']
      });
      const markers = map.queryRenderedFeatures(e.point, {
        layers: ['markers']
      });
      
      let last = false;
      let name = null;
      if (clusters && clusters.length) {
        const pointsInCluster = features.filter(function(f) {
          const pointPixels = map.project(f.geometry.coordinates)
          const pixelDistance = Math.sqrt(
            Math.pow(e.point.x - pointPixels.x, 2) + 
            Math.pow(e.point.y - pointPixels.y, 2) 
          );
          return Math.abs(pixelDistance) <= 40;
        });
        let states = [];
        pointsInCluster.forEach((point) => {
          const state = point.properties.state;
          if (states.indexOf(state) === -1) {
            states.push(state);
          }
        });
        if (states.length === 1) {
          last = true;
          name = states[0];
        }
      }

      if (markers && markers.length) {
        last = true;
        name = markers[0].properties.state;
      }
 
      if (last) {
        updateState(name);
        return;
      }
      
      if (clusters.length) {
        map.flyTo({
          center: e.lngLat,
          zoom: map.getZoom() + 2
        });
        return;
      }

      // Click on state
      $.ajax({
        url: 'https://api.opencagedata.com/geocode/v1/json',
        method: 'GET',
        data: {
          key: '8ea84acfd9f04dabadda253398552020',
          q: e.lngLat.lat + ',' + e.lngLat.lng,
        },
        dataType: 'json',
        statusCode: {
          200: function(response){  // success
            var name = response.results[0].components.state;
            if (data[currentTab].state.indexOf(name) > -1) {
              updateState(name);
              return;
            }
          },
        }
      });
    });

    const flyToResult = (geocoderResponse) => {
      geocoder.spot = geocoderResponse.result;
      map.flyTo({
        center: geocoder.spot.center,
        zoom: 8
      });
    };

    const assembleMap = (output) => {
      if (loaded) {
        map.addLayer({
          id: 'points',
          type: 'circle',
          source: {
            type: 'geojson',
            data: output,
            cluster: true,
            clusterRadius: 40,
            clusterMaxZoom: 15
          },
          paint: {
            'circle-color': {
              property: 'point_count',
              type: 'exponential',
              stops: [
                [{
                  zoom: 1,
                  value: 5
                }, '#58C30D'],
                [{
                  zoom: 5,
                  value: 20
                }, '#58C30D']
              ]
            },
            'circle-radius': {
              property: "point_count",
              type: "exponential",
              stops: [
                [{
                  zoom: 1,
                  value: 2
                }, 10],
                [{
                  zoom: 6,
                  value: 2
                }, 15],
                [{
                  zoom: 1,
                  value: 100
                }, 30],
                [{
                  zoom: 4,
                  value: 50
                }, 20],
              ]
            },
            'circle-stroke-color': 'white',
            'circle-stroke-width': 2
          },
          filter: [">", "point_count", 1]
        }).addLayer({
          id: "labels",
          type: 'symbol',
          source: 'points',
          layout: {
            "text-field": "{point_count}",
            "text-font": ["Glegoo Regular"],
            "text-size": 16,
            "text-offset": [0, 0]
          },
          paint: {
            "text-color": "white",
          }
        }).addLayer({
          id: 'markers',
          type: 'symbol',
          source: 'points',
          layout: {
            'icon-image': 'marker-green',
            'icon-size': .8,
            'icon-allow-overlap': true
          },
          filter: ["any", ["!has", "point_count"],
          ["==", "point_count", 1]
          ]
        });
      } else {
        setTimeout(() => { assembleMap(output); }, 500)
      }
    };

    const processData = (mapData) => {
      if (!mapData || mapData.length === 0) {
        return;
      }
      const output = {
        type: "FeatureCollection",
        features: []
      };
  
      mapData.forEach((point, i) => {
        const geojson = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [point.lon, point.lat],
          },
          properties: {
            id: i,
            state: point.state,
          }
        };
        output.features.push(geojson);
        features.push(geojson);
      });
    
      assembleMap(output);
    };

    const geocoder = new window.MapboxGeocoder({
      accessToken: window.mapboxgl.accessToken,
      flyTo: false,
    });
    geocoder.on('result', flyToResult);
    processData(mapbox[currentTab]);
  };

  return (
    <div id="map">
      <div id="map-container"></div>
    </div>
  );
};

export default React.memo(Map);
