import React, { useState, useEffect } from 'react';
import { gapi } from 'gapi-script';
import CountUp from 'react-countup';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import $ from 'jquery';
import close from './close.png';
import play from './icon-play-2.png';
import Map from './Map';
import AnimatedPixelify from './AnimatedPixelify';
import './App.css';
import mapCoords from './mapCoords';
import stateLabels from './stateLabels';

const dataLabels = {
  gender: 'Gênero',
  race: 'Etnia',
  state: 'Estado',
  city: 'Cidade',
};

const AutoplaySlider = withAutoplay(AwesomeSlider);
const sliderInterval = 15000;

function Dashboard({ ranges, enabledTabs, hideRightBar, year }) {
  const [currentTab, setCurrentTab] = useState('enrol');
  const [currentSubTab, setCurrentSubTab] = useState('default');
  const [data, setData] = useState({});
  const [dataByState, setDataByState] = useState({});
  const [state, setState] = useState(null);
  const [mapbox, setMapbox] = useState({});
  const [loaded, setLoaded] = useState(false);

  // FIXME: Use the t() function to translate strings
  const t = (str) => {
    return str;
  };

  const currentData = state ? dataByState[state] : data;
  const currentTitle = state ? stateLabels[state] : t('Brasil');

  useEffect(() => {
    gapi.load('client', function() {
      gapi.client
        .init({
          apiKey: 'AIzaSyApxSpL08Sb5gvVvhXGWUgBVUKHivPY7-U',
          discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
        })
        .then(() => {
          gapi.client.load('sheets', 'v4', () => {
            gapi.client.sheets.spreadsheets.values
              .batchGet({
                spreadsheetId: '1UGQED1PiesrOv259a9ETXKUvCPc8uhigCxWJCGxWswk',
                ranges: ranges.filter(r => r !== ''),
              })
              .then(
                response => {
                  const data = { enrol: { total: 0, gender: {}, city: {}, state: {}, race: {} } };
                  const mapbox = { enrol: [] };
                  const dataByState = {};

                  if (ranges[0]) {
                    const rawData = response.result.valueRanges[0].values;
                    rawData.forEach((row, index) => {
                      const state = row[2];
                      if (!dataByState[state]) {
                        dataByState[state] = { enrol: { gender: {}, race: {}, total: 0 } };
                      }
                      data.enrol.total += 1;
                      dataByState[state].enrol.total += 1;
                      ['city', 'state', 'race', 'gender'].forEach((key, i) => {
                        const value = row[i + 1];
                        if (!data.enrol[key][value]) {
                          data.enrol[key][value] = 0;
                        }
                        data.enrol[key][value] += 1;
                        if (dataByState[state].enrol[key]) {
                          if (!dataByState[state].enrol[key][value]) {
                            dataByState[state].enrol[key][value] = 0;
                          }
                          dataByState[state].enrol[key][value] += 1;
                        }
                      });
                      mapbox.enrol.push({
                        index,
                        state,
                        lat: mapCoords[state][0],
                        lon: mapCoords[state][1],
                      });
                    });
                  }

                  if (ranges[1]) {
                    const rawData2 = response.result.valueRanges[1].values;
                    data.formation = { total: 0, gender: {}, city: {}, state: {}, race: {} };
                    mapbox.formation = [];
                    rawData2.forEach((row, index) => {
                      const state = row[2];
                      if (!dataByState[state].formation) {
                        dataByState[state].formation = { gender: {}, race: {}, total: 0 };
                      }
                      data.formation.total += 1;
                      dataByState[state].formation.total += 1;
                      ['city', 'state', 'race', 'gender'].forEach((key, i) => {
                        const value = row[i + 1];
                        if (!data.formation[key][value]) {
                          data.formation[key][value] = 0;
                        }
                        data.formation[key][value] += 1;
                        if (dataByState[state].formation[key]) {
                          if (!dataByState[state].formation[key][value]) {
                            dataByState[state].formation[key][value] = 0;
                          }
                          dataByState[state].formation[key][value] += 1;
                        }
                      });
                      mapbox.formation.push({
                        index,
                        state,
                        lat: mapCoords[state][0],
                        lon: mapCoords[state][1],
                      });
                    });
                  }

                  if (ranges[2]) {
                    const rawData3 = response.result.valueRanges[2].values;
                    data.ambassadors = { total: 0, gender: {}, city: {}, state: {}, race: {} };
                    mapbox.ambassadors = [];
                    rawData3.forEach((row, index) => {
                      const state = row[2];
                      if (!dataByState[state].ambassadors) {
                        dataByState[state].ambassadors = { gender: {}, race: {}, total: 0, person: { video: row[10] } };
                      }
                      data.ambassadors.total += 1;
                      dataByState[state].ambassadors.total += 1;
                      ['city', 'state', 'race', 'gender'].forEach((key, i) => {
                        const value = row[i + 1];
                        if (!data.ambassadors[key][value]) {
                          data.ambassadors[key][value] = 0;
                        }
                        data.ambassadors[key][value] += 1;
                        if (dataByState[state].ambassadors[key]) {
                          if (!dataByState[state].ambassadors[key][value]) {
                            dataByState[state].ambassadors[key][value] = 0;
                          }
                          dataByState[state].ambassadors[key][value] += 1;
                        }
                      });
                      mapbox.ambassadors.push({
                        index,
                        state,
                        lat: mapCoords[state][0],
                        lon: mapCoords[state][1],
                      });
                    });
                  }
                  
                  if (ranges[3]) {
                    const rawData4 = response.result.valueRanges[3].values;
                    data.formationEngagement = [];
                    rawData4.forEach((row) => {
                      data.formationEngagement.push({
                        value: row[0],
                        category: row[1],
                        icon: row[2],
                      });
                    });
                  }

                  if (ranges[4]) {
                    const rawData5 = response.result.valueRanges[4].values;
                    data.formationContent = [];
                    rawData5.forEach((row) => {
                      data.formationContent.push({
                        value: row[0],
                        category: row[1],
                        icon: row[2],
                      });
                    });
                  }

                  if (ranges[5]) {
                    const rawData6 = response.result.valueRanges[5].values;
                    data.formationEvaluation = [];
                    rawData6.forEach((row) => {
                      data.formationEvaluation.push({
                        value: row[0],
                        category: row[1],
                      });
                    });
                  }

                  if (ranges[6]) {
                    const rawData7 = response.result.valueRanges[6].values;
                    data.quotes = [];
                    rawData7.forEach((row) => {
                      data.quotes.push(row[0]);
                    });
                  }

                  if (ranges[7]) {
                    const rawData8 = response.result.valueRanges[7].values;
                    data.ambassadorsEngagement = [];
                    rawData8.forEach((row) => {
                      data.ambassadorsEngagement.push({
                        value: row[0],
                        category: row[1],
                        icon: row[2],
                      });
                    });
                  }

                  if (ranges[8]) {
                    const rawData9 = response.result.valueRanges[8].values;
                    data.ambassadorsEngagementStats = [];
                    rawData9.forEach((row) => {
                      data.ambassadorsEngagementStats.push({
                        value: row[0],
                        category: row[1],
                        icon: row[2],
                      });
                    });
                  }

                  if (ranges[9]) {
                    const rawData10 = response.result.valueRanges[9].values;
                    data.ambassadorsEngagementActivity = [];
                    rawData10.forEach((row) => {
                      data.ambassadorsEngagementActivity.push({
                        value: row[0],
                        category: row[1],
                        icon: row[2],
                      });
                    });
                  }

                  if (ranges[10]) {
                    const rawData11 = response.result.valueRanges[10].values;
                    data.ambassadorsQuotes = [];
                    rawData11.forEach((row) => {
                      data.ambassadorsQuotes.push(row[0]);
                    });
                  }

                  if (ranges[11]) {
                    const rawData12 = response.result.valueRanges[11].values;
                    data.ambassadorsEvaluation = [];
                    rawData12.forEach((row) => {
                      data.ambassadorsEvaluation.push({
                        value: row[0],
                        category: row[1],
                      });
                    });
                  }

                  if (ranges[12]) {
                    const rawData13 = response.result.valueRanges[12].values;
                    let header = [];
                    rawData13.forEach((row, i) => {
                      if (i === 0) {
                        header = row;
                      } else {
                        row.forEach((value, j) => {
                          const state = row[1];
                          if (!dataByState[state].ambassadors) {
                            dataByState[state].ambassadors = { gender: {}, race: {}, total: 0, person: {} };
                          }
                          if (j !== 1) {
                            dataByState[state].ambassadors.person[header[j]] = value;
                          }
                        });
                      }
                    });
                  }

                  setState(null);
                  setData(data);
                  setDataByState(dataByState);
                  setMapbox(mapbox);
                  setLoaded(true);
                }
              )
          })
        })
    });
  }, [ranges]);
  
  useEffect(() => {
    window.setTimeout(
      () => {
        $('.animate-width').each((i, bar) => {
          const $bar = $(bar);
          $bar.animate({ width: $bar.attr('data-width') }, 2000);
        });
      },
      1000,
    );
  });

  const formatPerc = (value, total) => {
    return (value / total * 100).toFixed(1) + '%';
  };

  const handleClickOnTab = (tab) => {
    if (enabledTabs.indexOf(tab) === -1) {
      alert('Em breve!');
    }
    else if (currentTab !== tab) {
      setCurrentTab(tab);
      setCurrentSubTab('default');
    }
  };

  const handleClickOnSubTab = (subTab) => {
    if (currentSubTab !== subTab) {
      setCurrentSubTab(subTab);
    }
  };

  const renderBars = (key) => {
    const label = dataLabels[key];
    return (
      <div className="bars">
        <div className="data-label">{t(label)}</div>
        {Object.keys(currentData[currentTab][key]).sort().map((k) => {
          const v = currentData[currentTab][key][k];
          const p = formatPerc(v, currentData[currentTab].total);
          return (
            <div className="bar-row" key={k}>
              <div className="bar-row-label">{t(k)} <small>[{p} = {v}]</small></div>
              <div className="bar-outer"><div className="bar-inner animate-width" style={{ width: 0 }} data-width={p} /></div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderAmbassador = () => {
    const data = dataByState[state].ambassadors.person;
    if (!data) {
      return null;
    }
    return (
      <div className="ambassador-card">
        <div className="data-label">{t('Ficha')}</div>
        <div className="ambassador-photo">
          <AnimatedPixelify state={state} key={state} year={year} />
        </div>
        { Object.keys(data).map((key) => {
          if (key === 'video') {
            return null;
          }
          let value = data[key];
          if (/^[0-9.]+$/.test(value)) {
            value = <CountUp start={0} end={parseFloat(value, 10)} />;
          }
          return (
            <div className="ambassador-card-row" key={key}>
              <span className="ambassador-card-label">{key}</span>: <span className="ambassador-card-value">{value}</span>
            </div>
          );
        })}
        <div className="ambassador-play">
          <a href={data.video} target="_blank" rel="noopener noreferrer"><img src={play} alt="" /></a>
        </div>
      </div>
    );
  };

  const handleCloseLeftBar = () => {
    setState(null);
  };

  const tabToTitle = {
    enrol: t('inscritos'),
    formation: t('selecionados'),
    ambassadors: t('embaixadores'),
  };

  const renderLeftBarData = () => {
    if (!currentData[currentTab]) {
      return null;
    }
    return (
      <div className={currentTab}>
        <div className="close" onClick={handleCloseLeftBar}>{ state ? <img src={close} alt={t('Voltar para os dados do Brasil')} /> : null }</div>
        <div className="place">{currentTitle}</div>
        <div className="total"><CountUp start={0} end={currentData[currentTab].total} /></div>
        <div className="subtitle">{tabToTitle[currentTab]}</div>
        {renderBars('gender')}
        {renderBars('race')}
        { state && currentTab === 'ambassadors' ? renderAmbassador() : null }
      </div>
    );
  };

  const renderRightBarData = () => {
    if (currentTab === 'formation' && (currentSubTab === 'engagement' || currentSubTab === 'default')) {
      return (
        <div>
          <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: 20 }}>
            { data.formationEngagement.map((row) => {
              const value = parseFloat(row.value, 10);
              if (value) {
                return (
                  <div className="icon-cell" key={row.category}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <div className="icon-cell-icon" id={`icon-${row.icon}`} />
                      <div className="icon-cell-number">
                        <CountUp start={0} end={value} />
                      </div>
                    </div>
                    <div className="icon-cell-label">{t(row.category)}</div>
                  </div>
                );
              }
              return null;
            })}
          </div>
          <h2 className="data-label right-bar-title">{t('Conteúdos Produzidos')}</h2>
          <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            { data.formationContent.map((row) => {
              const value = parseFloat(row.value, 10);
              if (value) {
                return (
                  <div className="icon-cell" key={row.category}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <div className="icon-cell-icon" id={`icon-${row.icon}`} />
                      <div className="icon-cell-number">
                        <CountUp start={0} end={value} />
                      </div>
                    </div>
                    <div className="icon-cell-label">{t(row.category)}</div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      );
    }
    if (currentTab === 'formation' && currentSubTab === 'evaluation') {
      return (
        <div id="formation-evaluation" className="evaluation">
          { data.formationEvaluation.map((row) => {
            const v = parseFloat(row.value, 10);
            const p = formatPerc(v, 1);
            return (
              <div className="bar-row" key={row.category}>
                <div className="bar-row-label">{t(row.category)} <small>[{p}]</small></div>
                <div className="bar-outer"><div className="bar-inner animate-width" style={{ width: 0 }} data-width={p} /></div>
              </div>
            );
          })}
          <AutoplaySlider play interval={sliderInterval} buttons={false}>
            { data.quotes.map((quote) => (
              <div className="quote" key={quote}>{t(quote)}</div>
            ))}
          </AutoplaySlider>
        </div>
      );
    }
    if (currentTab === 'ambassadors' && (currentSubTab === 'engagement' || currentSubTab === 'default')) {
      if (!data.ambassadorsEngagement) {
        return null;
      }
      return (
        <div>
          <h2 className="data-label right-bar-title">{t('Destaques')}</h2>
          <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            { data.ambassadorsEngagement.map((row) => (
              <div className="icon-cell icon-highlight" key={row.category}>
                <div className="icon-cell-icon" id={`icon-${row.icon}`} />
                <div className="icon-cell-number">
                { isNaN(row.value) ? row.value : <CountUp start={0} end={parseFloat(row.value, 10)} /> }
                </div>
                <div className="icon-cell-label">{t(row.category)}</div>
              </div>
            ))}
          </div>
          <h2 className="data-label right-bar-title">{t('Indicadores')}</h2>
          <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            { data.ambassadorsEngagementStats.map((row) => (
              <div className="icon-cell icon-highlight" key={row.category}>
                <div className="icon-cell-icon" id={`icon-${row.icon}`} />
                <div className="icon-cell-number">
                { isNaN(row.value) ? row.value : <CountUp start={0} end={parseFloat(row.value, 10)} /> }
                </div>
                <div className="icon-cell-label">{t(row.category)}</div>
              </div>
            ))}
          </div>
          <h2 className="data-label right-bar-title">{t('Atividades')}</h2>
          <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            { data.ambassadorsEngagementActivity.map((row) => (
              <div className="icon-cell" key={row.category}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="icon-cell-icon" id={`icon-${row.icon}`} />
                  <div className="icon-cell-number">
                  { isNaN(row.value) ? row.value : <CountUp start={0} end={parseFloat(row.value, 10)} /> }
                  </div>
                </div>
                <div className="icon-cell-label">{t(row.category)}</div>
              </div>
            ))}
          </div>
        </div>
      );
    }
    if (currentTab === 'ambassadors' && currentSubTab === 'evaluation') {
      return (
        <div id="ambassadors-evaluation" className="evaluation">
          { data.ambassadorsEvaluation.map((row) => {
            let { value } = row;
            if (/%/.test(value)) {
              value = parseFloat(value.replace('%', ''), 10) / 100;
              const v = parseFloat(value, 10);
              const p = formatPerc(v, 1);
              return (
                <div className="bar-row" key={row.category}>
                  <div className="bar-row-label">{t(row.category)} <small>[{p}]</small></div>
                  <div className="bar-outer"><div className="bar-inner animate-width" style={{ width: 0 }} data-width={p} /></div>
                </div>
              );
            }
            return (
              <div className="bar-row" key={row.category}>
                <div className="bar-row-label">{t(row.category)}</div>
                <div className="bar-row-value">{value}</div>
              </div>
            );
          })}
          <div id="ambassadors-quotes">
            <AutoplaySlider play interval={sliderInterval} buttons={false}>
              { data.ambassadorsQuotes.map((quote) => (
                <div className="quote" key={quote}>{t(quote)}</div>
              ))}
            </AutoplaySlider>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderSentence = () => {
    if (data[currentTab] && data[currentTab].total) {
      return (
        <React.Fragment>
          <b><CountUp start={0} end={data[currentTab].total} /></b> {tabToTitle[currentTab]}, {t('de')} <b><CountUp start={0} end={Object.keys(data[currentTab].city).length} /></b> {t('municípios, de')} <b><CountUp start={0} end={Object.keys(data[currentTab].state).length} /></b> {t('UFs')}.
        </React.Fragment>
      );
    }
    return null;
  };

  return (
    <div className="Dashboard">
      { loaded ?
        <React.Fragment>
          <div id="chart">
            <div id="left">
              {renderLeftBarData()}
            </div>
            <div id="middle">
              <div className="tabs">
                <div className={currentTab === 'enrol' ? 'active' : ''} onClick={() => { handleClickOnTab('enrol'); }}>{t('Inscrições')}</div>
                <div className={currentTab === 'formation' ? 'active' : ''} onClick={() => { handleClickOnTab('formation'); }}>{t('Formação')}</div>
                <div className={currentTab === 'ambassadors' ? 'active' : ''} onClick={() => { handleClickOnTab('ambassadors'); }}>{t('Embaixadores')}</div>
              </div>
              <p className="map-sentence">{renderSentence()}</p>
              <Map data={data} currentTab={currentTab} updateState={setState} mapbox={mapbox} />
            </div>
            <div id="right" style={{ display: (currentTab === 'enrol' || (currentTab === 'ambassadors' && hideRightBar)) ? 'none' : 'block' }}>
              <div className="tabs">
                <div className={(currentSubTab === 'engagement' || currentSubTab === 'default') ? 'active' : ''} onClick={() => { handleClickOnSubTab('engagement'); }}>{t('Engajamento')}</div>
                <div className={currentSubTab === 'evaluation' ? 'active' : ''} onClick={() => { handleClickOnSubTab('evaluation'); }}>{t('Avaliação')}</div>
              </div>
              <div id="right-inner">{renderRightBarData()}</div>
            </div>
          </div>
        </React.Fragment> :
        <div id="loading">
          {t('Carregando...')}
        </div> }
    </div>
  );
}

export default Dashboard;
